import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../models/i18n';

class Timeframe extends PureComponent {
  static propTypes = {
    timeframe: PropTypes.string.isRequired,
    pushTimeframe: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired
  };

  render() {
    const { timeframe, pushTimeframe } = this.props;
    return (
      <div className="form-group">
        <label className="select-text">{I18n.translate('data', 'choose')}</label>
        <select className="form-control" value={timeframe} onChange={pushTimeframe}>
          <option className="select-option" value="last_day">
            {I18n.translate('data', 'giorno')}
          </option>
          <option className="select-option" value="last_week">
            {I18n.translate('data', 'settimana')}
          </option>
          <option className="select-option" value="last_month">
            {I18n.translate('data', 'mese')}
          </option>
          <option className="select-option" value="last_year">
            {I18n.translate('data', 'anno')}
          </option>
          <option className="select-option" value="custom">
            {I18n.translate('data', 'custom')}
          </option>
        </select>
      </div>
    );
  }
}

export default Timeframe;
