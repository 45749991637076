import React, { PureComponent } from 'react';
import { setCurrentStation } from '../../models/station';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StationsMap from './StationsMap';
import I18n from '../../models/i18n';
import { getStationLink } from './StationsUtils';
import i18n from '../../models/i18n';

class StationsPage extends PureComponent {
  state = {
    stations: {},
    currentStation: {}
  };

  render() {
    const { stations } = this.state;
    if (!stations.rows) {
      return <div className="pt-5 mt-5 text-center">Loading</div>;
    }
    if (stations.rows.length === 0) {
      return <div className="pt-5 mt-5 text-center">{I18n.translate('stations', 'no_stations')}</div>;
    }
    return (
      <div className="stations-page">
        <div className="top-page">
          <StationsMap stations={stations.rows} onStationClick={this.saveCurrentStation} embed={this.isEmbed} />
        </div>
        <div className="container mt-4">
          <div className="shadow-lg card mb-4">
            <div className="card-header graph-header">
              <div className="row">
                <div className="col-10">
                  <div className="card-header-text">
                    <h3 className="chart-title py-2">{I18n.translate('stations', 'title')}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">{I18n.translate('stations', 'code')}</th>
                    <th scope="col">{I18n.translate('stations', 'name')}</th>
                    <th scope="col">{I18n.translate('stations', 'station_type')}</th>
                    <th scope="col">Sponsor</th>
                  </tr>
                </thead>
                <tbody>
                  {stations.rows &&
                    stations.rows.map((station, i) => {
                      return this.getStationRow(station, i);
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
    this.isEmbed = this.props.location.pathname.indexOf('/embed') > 0;
  }

  fetchStations() {
    const { search } = this.props.location;
    fetch(`/api/v1/stations${search}`)
      .then(res => {
        return res.json();
      })
      .then(stations => this.setState({ stations }));
  }

  saveCurrentStation = (e, station) => {
    this.props.dispatch(setCurrentStation(station));
  };

  getStationRow(station, i) {
    return (
      <tr key={i} style={{ cursor: 'pointer', maxHeight: 'inherit' }}>
        <td>{station.code}</td>
        <td>{getStationLink(station, this.isEmbed, e => this.saveCurrentStation(e, station))}</td>
        <td>{station.station_type}</td>
        <td style={{ textAlign: 'right' }}>
          {station.sponsor && station.sponsor.name && (
            <>
              {i18n.translate('sponsor', 'thanks')} <b>{station.sponsor.name}</b>
              <br />
              {station.sponsor.site && (
                <a href={station.sponsor.site} target="_blank" rel="noopener noreferrer">
                  <img
                    style={{ maxWidth: 200, maxHeight: 75 }}
                    alt={station.sponsor.name}
                    src={
                      station.sponsor.options.logo
                        ? station.sponsor.options.logo
                        : `/api/v1/sponsors/${station.sponsor.id}/icon`
                    }
                  />
                </a>
              )}
            </>
          )}
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  const { station } = state;

  return {
    station
  };
}

export default withRouter(connect(mapStateToProps)(StationsPage));
