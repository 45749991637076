import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import I18n from '../../models/i18n';
import TitleLine from '../CommonComponents/TitleLine';
import IconSection from '../IconSection';
import { setCurrentStation } from '../../models/station';
import { connect } from 'react-redux';

class Home extends PureComponent {
  state = {
    stations: {},
    currentStation: {}
  };

  render() {
    return (
      <div className="home-page">
        <div className="fcub-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 offset-xl-1 col-xl-7">
                <div className="fcub-header-text">
                  <h1 className="fcub-title">{I18n.translate('home', 'title')}</h1>
                  <TitleLine color={'green-title'} />
                  <h3 className="fcub-subtitle">{I18n.translate('home', 'subtitle')}</h3>
                  <div className="hashtag-wrapper text-center">
                    <a href="/stations" className="fcub-hashtags">
                      {I18n.translate('home', 'look_at')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IconSection />
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
  }

  fetchStations() {
    fetch('/api/v1/stations')
      .then(res => {
        return res.json();
      })
      .then(stations => this.setState({ stations }));
  }

  saveCurrentStation(e, station) {
    this.props.dispatch(setCurrentStation(station));
  }
}

function mapStateToProps(state) {
  const { station } = state;

  return {
    station
  };
}

export default withRouter(connect(mapStateToProps)(Home));
