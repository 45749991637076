import React, { PureComponent } from 'react';
import I18n from '../../models/i18n';
import PropTypes from 'prop-types';

class Footer extends PureComponent {
  static propTypes = {
    config: PropTypes.object.isRequired
  };

  render() {
    const { sponsors, linkOpenData } = this.props.config;
    return (
      <div className="fcub-footer" id="fcub-footer">
        {sponsors && sponsors.length > 0 && (
          <div className="upperfooter py-4">
            <div className="container">
              <div className="row">
                {sponsors.map(sponsor => {
                  return (
                    <div className="col py-3 text-center" key={sponsor.link}>
                      <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
                        <img
                          className="a2a-logo img-fluid"
                          src={sponsor.logo}
                          alt={sponsor.name + ' logo'}
                          title={sponsor.name}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <footer className="footer mt-auto py-3 bg-green-footer">
          <div className="container">
            <div className="text-footer text-center">
              <a className="link-readme" href="https://git.fluidware.it/fcub" target="_blank" rel="noopener noreferrer">
                {I18n.translate('footer', 'sources')}
              </a>
              {linkOpenData && (
                <>
                  <span className="mx-3">-</span>
                  <a
                    className="link-readme"
                    href="https://www.milanoscaloromana.it/PM/opendata/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {I18n.translate('footer', 'link_dati')}
                  </a>
                </>
              )}
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
